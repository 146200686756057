<template>
	<div>
		<v-text-field
			filled
			rounded
			:disabled="isDisabled"
			:value="value"
			:type="question.input_type"
			@change="(value) => $emit('update', value)"
		></v-text-field>
	</div>
</template>

<script type="text/javascript">
export default {
	name: "Input",
	props: {
		value: {},
	},
	inject: ["question", "disabled"],
	computed: {
		isDisabled(){
			return this.disabled || this.question.disable_input
		}
    },
};
//
</script>
"
